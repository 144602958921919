
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IUserChangePassword, IUserChangeRES } from "@common/user";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "Settings",
	data: () => {
		return {
			passwords: {
				current: "" as string,
				new: "" as string,
				confirmNew: "" as string
			},
			hidePassword: {
				current: true as boolean,
				new: true as boolean,
				confirmNew: true as boolean
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			}

		};
	},
	created () {
		this.store.dispatch.changeAppTitle("Settings");
	},
	methods: {
		async changePassword () {
			try {
				const data: IUserChangePassword = {
					currentPassword: this.passwords.current,
					newPassword: this.passwords.new
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/user/password/change`,
				};
				const res: AxiosResponse<IServerRES<IUserChangeRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						this.snackbar = {
							show: true,
							color: "success",
							text: "Password was succesfully changed.",
						};
					} else {
						this.snackbar = {
							show: true,
							color: "error",
							text: "Error! Try again!",
						};
					}
				}
			} catch (err) {
				console.error(err);
			}

			this.resetValues();
		},
		resetValues () {
			this.passwords.current = "";
			this.passwords.new = "";
			this.passwords.confirmNew = "";

			this.hidePassword.current = true;
			this.hidePassword.new = true;
			this.hidePassword.confirmNew = true;
		}
	}
});
